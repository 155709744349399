import React, { useEffect } from 'react';
import Layout from '../../components/structures/Layout/layout.js';
import Section from '../../components/structures/Section/section';
import Wrapper from '../../components/structures/Wrapper/wrapper';
import RichText from '../../components/modules/RichText/richText';
import { Title } from '../../components/elements/Title/title';
import Column from '../../components/structures/Column/column';
import redirect from '../../services/redirect/redirect';
import { Card } from '../../components/modules/Card/card';
import { CardGroup } from '../../components/modules/CardGroup/cardGroup';
import { Button } from '../../components/elements/Button/button';
import replaceVariableSection from '../../services/replaceVariableSection/replaceVariableSection';
import { useSite } from '../../context/siteContext';

const Static = (props) => {
  const location = props.path;
  const variables = require('../../data/variables.json');
  const variablesForms = variables.forms;

  let sections = props.pageContext.sections;
  let sectionAboutUsForm = props.pageContext.sectionAboutUsForm;
  const dataSite = props.pageContext.dataSite;
  let { forms, feeBiometrical } = useSite() ? useSite() : {};

  /**
   * replace variables Contntentful with API data
   * unless get data from siteContext
   */

  for (const form in variablesForms) {
    if (typeof dataSite.data !== 'undefined') {
      sections = replaceVariableSection(variablesForms[form].price, dataSite.data.forms[form].price, sections);
      sections = replaceVariableSection(variablesForms[form].fee, dataSite.data.forms[form].fee, sections);
    } else {
      sections = replaceVariableSection(variablesForms[form].price, typeof forms !== 'undefined' && forms[form] ? forms[form].price : '', sections);
      sections = replaceVariableSection(variablesForms[form].fee, typeof forms !== 'undefined' && forms[form] ? forms[form].fee : '', sections);
    }
  }
  sections = replaceVariableSection('{{FEE_BIOMETRICAL_US}}', feeBiometrical ? feeBiometrical : '', sections);

  const name = props.pageContext.name;
  const isCard = props.pageContext.isCard;
  const templateStyle = props.pageContext.templateStyle;

  let aboutUsForms;
  location === '/about-us' && templateStyle.static.aboutUsForms && (aboutUsForms = true);

  useEffect(() => {
    redirect();
  }, []);

  return (
    <Layout
      nav={true}
      typeHeader={templateStyle.header.type}
      prefooter={templateStyle.prefooter.type}
      pageContext={props.pageContext}
      location={location}
      footer={templateStyle.footer.type}
      dataSite={dataSite}
    >
      <Section light name={`pdf`}>
        <Wrapper middle={templateStyle.static.wrapperMiddle} row>
          <Column colMd={`12`} colLg={`12`}>
            <Title
              fontStyle={templateStyle.static.titleCantered && 'centered'}
              tag={`h1`}
              content={name}
            />
            {sections.map((section, key) => {
              return (
                <div key={key}>
                  {section.content.map((content, k) => {
                    if (content.textBody) {
                      return (
                        <div key={k}>
                          {section.title && (
                            <Title tag={`h2`} size="small" content={section.title} />
                          )}
                          {content.textBody && <RichText content={content.textBody} />}
                        </div>
                      );
                    }
                    if (content.cards) {
                      if (isCard) {
                        return (
                          <CardGroup key={k}>
                            {content.cards.map((card, v) => {
                              return card.bodyText ? (
                                <Card
                                  borderRadius
                                  white
                                  shadow
                                  headPrimary
                                  headPadSmall
                                  headCentered={templateStyle.static.cardTitleCentered}
                                  key={v}
                                  col={12}
                                  colLg={12}
                                  headContent={
                                    card.headText && <Title size="small" content={card.headText} />
                                  }
                                >
                                  {card.bodyText && <RichText content={card.bodyText} />}
                                </Card>
                              ) : null;
                            })}
                          </CardGroup>
                        );
                      } else {
                        return (
                          <div key={k}>
                            {content.cards.map((card, y) => {
                              return (
                                <div key={y}>
                                  <Title size="small" tag={`h2`} content={card.headText} />
                                  {card.bodyText && <RichText content={card.bodyText} />}
                                </div>
                              );
                            })}
                          </div>
                        );
                      }
                    }
                    return '';
                  })}
                </div>
              );
            })}
            {aboutUsForms && (
              <>
                <Title
                  tag={`h2`}
                  fontStyle={templateStyle.static.titleCantered && 'centered'}
                  size="small"
                  content={sectionAboutUsForm.title}
                />
                <CardGroup>
                  {sectionAboutUsForm.content[0].cards.map((card, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Card
                          row
                          borderRadius
                          white
                          shadow
                          headPrimary
                          headPadSmall
                          headCentered={templateStyle.static.cardTitleCentered}
                          col={12}
                          colLg={12}
                          headContent={
                            card.headText && (
                              <Title size="small" content={card.headText + ` - ` + card.formText} />
                            )
                          }
                        >
                          <Column colMd={`7`} colLg={`7`}>
                            {card.bodyText && <RichText content={card.bodyText} />}
                          </Column>
                          <Column colMd={`5`} colLg={`5`}>
                            <Button
                              type="brand"
                              size="large"
                              centered
                              iconArrow
                              primaryIcon={card.hasArrow}
                              link={`/` + card.btnLink}
                              content={card.btnText}
                            />
                          </Column>
                        </Card>
                      </React.Fragment>
                    );
                  })}
                </CardGroup>
              </>
            )}
          </Column>
        </Wrapper>
      </Section>
    </Layout>
  );
};

export default Static;
